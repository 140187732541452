import 'leaflet';
import 'slick-slider';
import './vendors/backgroundVideo.js';

$(document).ready(function() {

  const $sliderList = $('.pk-slider .slider-list'); 
  $sliderList.slick({
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 500,
      arrows: false,
      cssEase: 'linear',
      pauseOnHover: true
  });
  $sliderList.css('display','block');

  const map = L.map('pk-map').setView([54.174903, 15.552641], 15);

  const pkIcon = L.icon({
    iconUrl: '../static/img/logo-with-bg.svg',
    iconSize:     [38, 95],
    iconAnchor:   [22, 94],
    popupAnchor:  [-3, -76]
  });

  const pkLogo = '../static/img/logo-only-text.svg';
  const link = 'https://www.google.pl/maps/place/Apartamenty+Gardens/@54.1748953,15.5525148,20.12z/data=!4m5!3m4!1s0x470015c86050ba77:0x81a265e7c26090a8!8m2!3d54.1749474!4d15.5525657';

  L.marker([54.174903, 15.552641], {icon: pkIcon}).addTo(map);

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(map);
  
  $('.video-fix').backgroundVideo();
  $('.pk-movie').css('display', 'block');


  // Hotres plugin
  createHotresChooser({'mode':'popup','style':'add','lang':'pl','auth':'f5e916bc1628588ec2f704d6560db11b'});

});